import { Wrapper } from '@maxtropy/components';
import { Button, Space, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EdgeDeviceTemplateForm from '@/shared/components/EdgeDeviceTemplateForm';
import { getEdgeTemplate, updateEdgeTemplate } from '../../api/edgeTemplate';
import { useQuery } from '../../utils/utils';
import styles from './index.module.scss';
import { apiV2EdgeTemplateAddPost } from '@maxtropy/tody-mgmt-apis-v2';

export interface EdgeDeviceTemplateFormPageProps {
  isEdit?: boolean;
  isCopy?: boolean;
}

const EdgeDeviceTemplateFormPage: React.FC<EdgeDeviceTemplateFormPageProps> = ({ isEdit = false, isCopy = false }) => {
  const routes = useMemo(
    () => [
      { name: 'Iot配置' },
      { name: '数采配置' },
      { name: '数采模板' },
      { name: isEdit ? '编辑基础信息' : '新建模板' },
    ],
    [isEdit]
  );
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const query = Object.fromEntries(urlSearchParams.entries());

  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useQuery(
    useCallback(() => (isEdit || isCopy ? getEdgeTemplate(+id!) : Promise.resolve(undefined)), [id, isEdit, isCopy])
  );

  const [form] = useForm();

  useEffect(() => {
    if (data) {
      const params: any = { ...data };
      if (isCopy) {
        params.name = `${data.name}的复制`;
        params.objectModalType = data.physicalModelId;
        // params.deviceModelId = data.deviceModelId;
      }
      form.setFieldsValue({ ...params });
    }
  }, [data, form, isCopy]);

  const [submitting, setSubmitting] = useState(false);
  const onSubmit = (configEdgeInfo: boolean = false) => {
    form.validateFields().then(() => {
      const values = form.getFieldsValue(true);
      const params: any = {
        name: values.name,
        deviceTypeId: values.deviceTypeId,
        deviceModelName: values.deviceModelName,
        remark: values.remark,
        iotProtocol: values.iotProtocol,
        driveType: values.driveType,
        physicalModelId: values.objectModalType,
      };

      if(isCopy) {
        params.edgeDeviceTemplateId = +id!;
      }
      
      const request = isEdit
        ? () => updateEdgeTemplate(+id!, params).then(() => +id!)
        : () =>
            apiV2EdgeTemplateAddPost(params).then(({ id }) => id);

      setSubmitting(true);
      request()
        .then(templateId => {
          if (configEdgeInfo) {
            navigate(`/device/edge/template/${templateId}/point?previous=true&from=edgeTemplate`);
          } else {
            navigate(`/device/edge/template${window.location.search}`, {
              replace: true,
            });
          }
        })
        .finally(() => setSubmitting(false));
    });
  };

  return (
    <Wrapper className={styles.wrapper} routes={routes}>
      <Spin spinning={isLoading}>
        <EdgeDeviceTemplateForm form={form} row={data} className={styles.form} isCopy={isCopy} isEdit={isEdit} />
      </Spin>
      <Space className="sticky-footer" style={{ paddingLeft: 60 }}>
        {(!isEdit || query.next) && (
          <Button type="primary" loading={submitting} onClick={() => onSubmit(true)}>
            保存并配置采集信息
          </Button>
        )}
        {!isCopy && (
          <Button type="primary" loading={submitting} onClick={() => onSubmit()}>
            保存
          </Button>
        )}
        <Button
          onClick={() =>
            navigate(`/device/edge/template${window.location.search}`, {
              replace: true,
            })
          }
        >
          取消
        </Button>
      </Space>
    </Wrapper>
  );
};

export default EdgeDeviceTemplateFormPage;
